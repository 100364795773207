// import AOS from "aos";
import lozad from "lozad";
import { header } from "./header";
import swiperInit from "./swiper";
import {
	setBackgroundElement,
	ToggleItem,
	detectCloseElement,
	buttonToTop,
	toggleCta,
	clickScrollToDiv,
	appendCaptchaASP,
	toggleBookPin,
} from "./helper";
// import { noUiSliderInit } from "./nouislider";
import { tocInit } from "./tocbot";
import { careerModal } from "./career";
import { toggleFilterMobile } from "./tour";
$(document).ready(function () {
	header.init();
	swiperInit();
	buttonToTop();
	toggleCta();
	setBackgroundElement();
	ToggleItem();
	// noUiSliderInit();
	tocInit();
	toggleBookPin();
	careerModal();
	toggleFilterMobile();
	countDownTime();
	expandText();
	datePicker();
	selectTourItem();
	wrapCaptionImg();
	InfinitieScrollPagination();
	menuSpyDay();
});
function InfinitieScrollPagination() {
	const $pager = $(".ModulePager");
	const $container = $(".wrap-item-append");
	const $loadMoreBtn = $(".button-add-more-product");

	// Early return if no pager exists
	if (!$pager.length) {
		$loadMoreBtn.remove();
		return; 
	}

	// Initialize infinite scroll
	const infScroll = $container.infiniteScroll({
		// Core options
		path: ".ModulePager",
		append: ".wrap-item-append .news-item",

		// UI elements
		status: ".scroller-status",
		hideNav: ".pagination",
		button: ".button-add-more-items",

		// Behavior
		checkLastPage: ".LastPage",
		history: "push",
		scrollThreshold: false, // Button-triggered loading

		// Performance options
		prefill: false,
		loadOnScroll: false,
		debug: false,
	});

	// Handle successful appends
	infScroll.on("append.infiniteScroll", function (event, body, path, items) {
		$(items).removeClass("is-big");
		// Lazy load new images
		lozad().observe();
	});

	// Handle errors
	infScroll.on("error.infiniteScroll", function (error, path) {
		console.error("Loading error:", path, error);
		$loadMoreBtn.addClass("is-disabled");
	});

	// Handle last page
	infScroll.on("last.infiniteScroll", function () {
		$loadMoreBtn.addClass("is-disabled").prop("disabled", true);
	});
}
function menuSpyDay() {
	const $scheduleContent = $(".schedule-content");
	const $scheduleItems = $(".schedule-item");
	const $scheduleLinks = $(".schedule-date");
	let waypoints = [];

	$scheduleItems.each(function (index) {
		waypoints.push(
			new Waypoint({
				element: this,
				context: $scheduleContent[0],
				offset: 100,
				handler: function (direction) {
					$scheduleLinks.removeClass("active");
					if (direction === "down") {
						$scheduleLinks.eq(index).addClass("active");
					} else {
						$scheduleLinks.eq(index).addClass("active");
					}
				},
			})
		);

		$scheduleLinks.eq(index).on("click", function (e) {
			// Disable waypoints
			waypoints.forEach((wp) => wp.disable());

			// Force active state
			$(this).addClass("active").siblings().removeClass("active");

			// Re-enable waypoints after a short delay
			setTimeout(() => {
				waypoints.forEach((wp) => wp.enable());
			}, 100);
		});
	});
}
function wrapCaptionImg() {
	$("img[longdesc]").each(function () {
		const $this = $(this);
		const $caption = $this.attr("longdesc");
		$this
			.wrap(`<figure></figure>`)
			.after(`<figcaption>${$caption}</figcaption>`);
	});
}
/*==================== Select ====================*/
function selectTourItem() {
	$(".tour-info-item select").selectmenu({
		change: function (event, ui) {
			const selectedValue = this.value;
			console.log("🚀 ~ selectTourItem ~ selectedValue:", selectedValue);
			window.location.href = selectedValue;
		},
	});
}
/*==================== Date Picker ====================*/
function datePicker() {
	$("#date, .datepicker").datepicker({
		minDate: 0, // This sets the minimum date to today
		dateFormat: "yy-mm-dd",
		beforeShowDay: function (date) {
			var today = new Date();
			today.setHours(0, 0, 0, 0);
			return [date >= today, ""];
		},
	});
}
/*==================== Expand Text ====================*/
function expandText() {
	$("p:has(a.text-blue-600)").each(function () {
		const $p = $(this);
		const $expandLink = $p.find("a.text-blue-600").eq(0);
		const $collapseLink = $p.find("a.text-blue-600").eq(1);
		const fullText = $p.clone().children().remove().end().text().trim();
		const words = fullText.split(/\s+/);

		if (words.length > 70) {
			const shortText = words.slice(0, 70).join(" ") + "... ";

			function toggleText(e) {
				e.preventDefault();
				const isExpanded = $p.data("expanded");
				if (isExpanded) {
					$p.html(shortText);
					$p.append($expandLink).append($collapseLink);
					$expandLink.show();
					$collapseLink.hide();
				} else {
					$p.html(fullText);
					$p.append($expandLink).append($collapseLink);
					$expandLink.hide();
					$collapseLink.show();
				}
				$p.data("expanded", !isExpanded);
			}

			$p.html(shortText);
			$p.append($expandLink).append($collapseLink);
			$collapseLink.hide();

			$expandLink.on("click", toggleText);
			$collapseLink.on("click", toggleText);

			$p.data("expanded", false);
		} else {
			$expandLink.hide();
			$collapseLink.hide();
		}
	});
}
/*==================== Count Down Time ====================*/
function countDownTime() {
	$(".count-down").each(function () {
		const $this = $(this);
		const $span = $this.find("span").first();
		const timeString = $span.text();
		const [hours, minutes, seconds] = timeString.split(":").map(Number);

		let totalSeconds = hours * 3600 + minutes * 60 + seconds;

		const timer = setInterval(() => {
			totalSeconds--;

			if (totalSeconds <= 0) {
				clearInterval(timer);
				$this.text("Expired");
			} else {
				const h = Math.floor(totalSeconds / 3600);
				const m = Math.floor((totalSeconds % 3600) / 60);
				const s = totalSeconds % 60;

				$span.text(
					`${h.toString().padStart(2, "0")}:${m
						.toString()
						.padStart(2, "0")}:${s.toString().padStart(2, "0")}`
				);
			}
		}, 1000);
	});
}

/*==================== Aos Init ====================*/
// AOS.init({
// 	offset: 100,
// });
/*==================== Lazyload JS ====================*/
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
window.lozad = observer;
const backgroundObserver = lozad(".lozad-bg", {
	threshold: 0.1,
});
observer.observe();
backgroundObserver.observe();

